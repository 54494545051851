import './HomeHeader.css';
import Earth from '../static/assets/images/earthcrop.png';
import Earth2 from '../static/assets/images/earthcrop2.png';
import Logo from '../static/assets/images/bonk_logo_header.png';
import { CarouselLogos } from './Carousel';
import React, { useEffect, useState } from 'react';
import { fetchBonkDataIntegrations } from '../services/strapi.service';
import { getNumIntegrations } from '../utils/handleAPIData';
import { Loading } from './Loading';
import { useNavigate } from 'react-router-dom';

export function HomeHeader() {
   const [loading, setLoading] = useState<boolean>(true);
   const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
   const [num_integrations, setNumIntegrations] = React.useState(0); //dynamic
   const [error, setError] = useState<Error | null>(null);
   var ReactRotatingText = require('react-rotating-text');
   const navigate = useNavigate();

   useEffect(() => {
      // setLoading(true);
      async function fetchData() {
         const data = await fetchBonkDataIntegrations();
         if (error) {
            setError(error);
         } else {
            setNumIntegrations(getNumIntegrations(data));
         }
      }
      fetchData();
      setLoading(false);
      const handleResize = () => {
         setIsMobile(window.innerWidth <= 768);
       };
       window.addEventListener('resize', handleResize);

       return () => {
         window.removeEventListener('resize', handleResize);
       };
   
   }, []);

   return loading ? (
      <Loading />
   ) : (
      <section className="home-header bg-yellow-gradient">
         <div className="introduction flex column">
            <div className="flex items-center">
               <img src={Logo} alt="logo" />
            </div>
            <p className="header-lh">
               <span>&nbsp; MORE THAN ORDINARY [DOG]</span>
            </p>
            {/* subtitle removed here */}
         </div>
         <div className="earth-carousel">
            <div className="earth-box">
               <div>
                  <div>
                     <h1>911+</h1>
                     <h2>HOLDERS</h2>
                  </div>
                  <div>
                     <h1>{num_integrations}</h1>
                     <h2>INTEGRATIONS</h2>
                  </div>
                  <div className='pointer' onClick={() => navigate('/chains')}>
                     <h1>11</h1>
                     <h2>CHAINS</h2>
                  </div>
               </div>
               <div className="flex column items-center">
{/*                   
                  <p>
                  
                     BONK is the social layer and community meme coin of Solana with deep integrations as a utility token 
                     across a wide base of applications and protocols within the Web3 ecosystem
                  </p> */}
                  {/* <p><span>MORE THAN ORDINARY [ DOG ]</span></p> */}
               </div>
            </div>
            <div className="carousel-logos">
               <CarouselLogos />
            </div>
            
            
            <img src={isMobile ? Earth : Earth2} alt="earth" />
            
            
         </div>
      </section>
   );
}
